import React, { useEffect, useRef } from "react";
import "../../../node_modules/swiper/swiper.min.css";
import striptags from "striptags";
import Link from "next/link";
import handleViewport from "react-in-viewport";
import { parseHtml } from "../../../utils/common";

import {
  Navigation,
  Scrollbar,
  A11y,
  Controller,
  Thumbs,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const WorkProcess = (props) => {
  const WorkProcessData = props.WorkProcessData;
  const { forwardedRef, inViewport } = props;
  const swiperRef = useRef(null);

  let slide = {
    modules: [Navigation, Controller, Scrollbar, A11y, Thumbs],
    loop: true,
    spaceBetween: 30,
    watchSlidesProgress: true,
    onSwiper: null,
    speed: 2000,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true,
    },
    pagination: {
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-next",
      prevEl: ".swiper-prev",
    },
    breakpoints: {
      1920: {
        slidesPerView: 1,
      },
      1028: {
        slidesPerView: 1,
      },
      600: {
        slidesPerView: 1,
      },
    },
  };

  const handlePrevButtonClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNextButtonClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };
  const titleWithoutPTag = WorkProcessData?.value?.title;

  useEffect(() => {
    console.clear();
  }, []);

  return (
    <>
      <section ref={forwardedRef} className="work-process-section">
        {inViewport && (
          <div className="work-process-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className="section-heading">
                    <span
                      itemProp="heading"
                      dangerouslySetInnerHTML={{
                        __html: titleWithoutPTag,
                      }}
                    ></span>
                    <p
                      itemProp="description"
                      dangerouslySetInnerHTML={{
                        __html: WorkProcessData?.value?.description,
                      }}
                    ></p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="work-process-slider">
                    <div className="swiper swiper-work-process">
                      <div className="swiper-arrow">
                        <div
                          className="swiper-prev"
                          onClick={handlePrevButtonClick}
                        ></div>
                        <div
                          className="swiper-next"
                          onClick={handleNextButtonClick}
                        ></div>
                      </div>
                      <Swiper {...slide} className="testimonials-slider">
                        {WorkProcessData?.value?.list?.map((val, index) => {
                          const urlHtml = val?.value?.url;
                          const hrefMatch = /href="([^"]+)"/.exec(urlHtml);
                          const href = hrefMatch ? hrefMatch[1] : "";
                          return (
                            <SwiperSlide key={index}>
                              <div className="work-process-item">
                                <div className="work-process-content">
                                  <div className="row work-process-row">
                                    <div className="col-md-6">
                                      <div className="work-process-left">
                                        <div className="work-process-number">
                                          0{index + 1}
                                        </div>
                                        <div className="work-process-details">
                                          <h3
                                            itemProp="name"
                                            dangerouslySetInnerHTML={{
                                              __html: val?.value?.work?.title,
                                            }}
                                          ></h3>
                                          <p
                                            className="font-size-18"
                                            itemProp="description"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                val?.value?.work?.description,
                                            }}
                                          ></p>
                                        </div>
                                        <div className="work-process-button">
                                          <Link
                                            href="/request-for-proposal-quote"
                                            className="btn btn-primary linkText"
                                            itemProp="link"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                val?.value?.work
                                                  ?.page_chooser_block?.title,
                                            }}
                                          ></Link>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="work-process-right">
                                        <div className="work-process-description">
                                          <p
                                            itemProp="description"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                val?.value?.work_description
                                                  ?.title,
                                            }}
                                          ></p>
                                          {/* <ul itemProp="list"> */}
                                          {parseHtml(
                                            val?.value?.work_description
                                              ?.description
                                          )}
                                          {/* </ul> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

const WorkProcessViewportBlock = handleViewport(WorkProcess);

export default WorkProcessViewportBlock;
