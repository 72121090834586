import { useLayoutEffect, useState } from 'react'
import HTMLReactParser from 'html-react-parser'

export const getFormattedIndex = (num) => {
  return num.toString().padStart(2, '0')
}

export const linkActions = {
  REDIRECT: "Redirect",
  CLICK: "Popup"
}

export const parseHtml = (htmlString = '', checkP = false) => {
  if (checkP && (!htmlString.startsWith("<p>") && !htmlString.endsWith("</p>"))) {
    return <p>{HTMLReactParser(htmlString)}</p>
  }
  return HTMLReactParser(htmlString);
}

export const acceptedFileTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

export const decodeHTMLEntities = (str) => {
  const element = he.decode(str);
  return element;
}

export const useCheckYOffset = (pageOffSet = 0, eleClass = '', operator = '>' ) => {
  const [elementClass, setElementClass] = useState('')

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset
      
      if(eval(`${scroll} ${operator} ${pageOffSet}`)){
        setElementClass(eleClass)
      } else {
        setElementClass('')
      }
    }
    listener()

    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return elementClass
}

export const PHONE_REGEX = /^[0-9+ ]{0,14}$/

export const SLIDER_STEPS = 500

export const scrollElementIntoView = (id) => {
  document.getElementById(id)?.scrollIntoView()
}

export const getCookie = (cname) => {
  if (cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return "";
}

export const getHref = (eleLink = "/") => {
  if (!!eleLink) {
    if (eleLink?.charAt(0) === "/") {
      return eleLink;
    }
    return "/" + eleLink;
  }
  return "/#";
};
