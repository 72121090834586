import React, { useState, useEffect } from "react";
import striptags from "striptags";
import Link from "next/link";
import Image from "next/image";
import { parseHtml } from "../../../utils/common";
import dynamic from "next/dynamic";

const HeroModal = dynamic(() => import("./HeroModal"));

const Hero = (props) => {
  const bannerData = props.bannerData;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const titleWithoutPTag = bannerData?.value?.title;

  return (
    <>
      <section className="hero-banner-section" style={{ minHeight: "500px" }}>
        <div className="hero-banner-inner">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-banner-content">
                  <div className="hero-banner-heading">
                    <div className="banner-heading-left">
                      <div className="main-heading">
                        <span>{parseHtml(titleWithoutPTag)}</span>
                      </div>
                      <div className="banner-description">
                        {parseHtml(bannerData?.value?.description)}
                      </div>
                    </div>
                    <div className="banner-heading-right">
                      <div className="banner-video">
                        <a href="#" itemProp="video" onClick={handleShow}>
                          <Image
                            width={300}
                            height={300}
                            src={bannerData?.value?.images[1]?.url}
                            alt={bannerData?.value?.images[1]?.alt_text ?? 'video thumbnail'}
                            className="banner-video-thumbnail"
                            priority
                            loading="eager"
                            sizes="(max-width: 768px) 75vw, (max-width: 1200px) 40vw, 25vw"
                            layout="responsive"
                          />
                          <div className="video-play-icon">
                            <Image
                              width={64}
                              height={94}
                              src={bannerData?.value?.images[2]?.url}
                              alt={bannerData?.value?.images[2]?.alt_text ?? 'video play'}
                              priority
                              loading="eager"
                              sizes="(max-width: 768px) 75vw, (max-width: 1200px) 40vw, 25vw"
                              layout="responsive"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="hero-banner-img">
                    <div className="banner-img">
                      <Image
                        src={bannerData?.value?.image?.url}
                        alt={bannerData?.value?.image?.alt_text ?? 'Python Development Services Company'}
                        width={1000}
                        height={1000}
                        priority
                        loading="eager"
                        layout="responsive"
                        quality={100}
                      />
                    </div>
                    <div className="banner-link">
                      <Link
                        href="/request-for-proposal-quote"
                        itemProp="link"
                        className="card-link"
                      >
                        <span className="card-link-text">
                          {bannerData?.value?.card_link_text}
                        </span>
                        <span className="arrow-down"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {show && (
        <HeroModal data={bannerData} show={show} handleClose={handleClose} />
      )}
    </>
  );
};

export default Hero;
